export function genArchiveState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    totalCount: 0,
    fetchNext: null,
  }
}

export default genArchiveState
