import { Archive } from 'Models/Archive'
import { archiveGetters } from './types'

export default {
  [archiveGetters.IS_LOADING]: state => state.isLoading,
  [archiveGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [archiveGetters.LIST]: state => state.list.map(item => new Archive(item)),
  [archiveGetters.LIST_COUNT]: state => state.totalCount || 0,
  [archiveGetters.FETCH_NEXT]: state => state.fetchNext,
}
