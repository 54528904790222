import { archiveMutations } from './types'

export default {
  [archiveMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [archiveMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [archiveMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [archiveMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [archiveMutations.SET_TOTAL_COUNT] (state, value) {
    state.totalCount = value
  },

  [archiveMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },
}
