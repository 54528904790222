import { archiveActions, archiveMutations, archiveGetters } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { LIST_PAGE_LIMIT } from '../constants'
import omitBy from 'lodash/omitBy'
import { userGetters } from 'Store/entities/User/types'
import { rootGet } from 'Store/helpers/rootHelpers'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

const loneSdkCall = new LoneSdkCall()

export default {
  async [archiveActions.SET_IS_LOADING] ({ commit }, isLoading) {
    commit(archiveMutations.SET_IS_LOADING, isLoading)
  },

  async [archiveActions.LOAD_LIST] ({ commit }, payload = {}) {
    commit(archiveMutations.SET_IS_LOADING, true)

    payload.include = [
      'archived_by',
      'customer',
      'customer.identity',
      'identity.basics',
    ].join(',')
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data, fetchNext, meta } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getArchives(payload)
    )

    commit(archiveMutations.SET_LIST, data)
    commit(archiveMutations.SET_TOTAL_COUNT, meta.total)
    commit(archiveMutations.SET_FETCH_NEXT, fetchNext)
    commit(archiveMutations.SET_IS_LOADING, false)
  },

  async [archiveActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[archiveGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(archiveMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await fetchNext()
    commit(archiveMutations.PUSH_LIST, data)
    commit(archiveMutations.SET_FETCH_NEXT, newFetchNext)

    commit(archiveMutations.SET_IS_NEXT_LOADING, false)
  },
}

function ensureRequiredFilters (payload) {
  const result = Object.assign({}, payload)
  result.filter = Object.assign({}, payload.filter)

  result.filter.platform = result.filter.platform ||
    rootGet(`entities/user/${userGetters.PLATFORM_ID}`)

  if (rootGet(`entities/user/${userGetters.IS_BROKER}`)) {
    result.filter.brokers = result.filter.brokers ||
      rootGet(`entities/user/${userGetters.ACCOUNT_ID}`)
  }

  return result
}

function normalizePayload (payload) {
  const result = Object.assign({}, payload)
  result.page = Object.assign({}, payload.page)

  result.page.limit = LIST_PAGE_LIMIT

  return omitBy(result, val => !val)
}
